import "./Pdf.css";

import one from "../../img/pdfFotky/01-1.png";
import two from "../../img/pdfFotky/02-1.png";
import three from "../../img/pdfFotky/03-1.png";
import four from "../../img/pdfFotky/04-1.png";
import five from "../../img/pdfFotky/05-1.png";
import six from "../../img/pdfFotky/06-1.png";
import seven from "../../img/pdfFotky/07-1.png";
import eight from "../../img/pdfFotky/08-1.png";
import nine from "../../img/pdfFotky/09-1.png";
import ten from "../../img/pdfFotky/010-1.png";
import eleven from "../../img/pdfFotky/011-1.png";
import twelve from "../../img/pdfFotky/012-1.png";
import thirteen from "../../img/pdfFotky/013-1.png";
import fourteen from "../../img/pdfFotky/014-1.png";
import fifteen from "../../img/pdfFotky/015-1.png";
import sixteen from "../../img/pdfFotky/016-1.png";
import seventeen from "../../img/pdfFotky/017-1.png";
import eighteen from "../../img/pdfFotky/018-1.png";
import nineteen from "../../img/pdfFotky/019-1.png";
import twenty from "../../img/pdfFotky/020-1.png";
import twentyone from "../../img/pdfFotky/021-1.png";
import twentytwo from "../../img/pdfFotky/022-1.png";
import twentythree from "../../img/pdfFotky/023-1.png";
import twentyfour from "../../img/pdfFotky/024-1.png";
import twentyfive from "../../img/pdfFotky/025-1.png";
import twentysix from "../../img/pdfFotky/026-1.png";
import twentyseven from "../../img/pdfFotky/027-1.png";
import twentyeight from "../../img/pdfFotky/028-1.png";

import { useState, useEffect } from "react";

import oneMobile from "../../img/pdfFotky/01-1_Mobile.png";
import twoMobile from "../../img/pdfFotky/02-1_Mobile.png";
import threeMobile from "../../img/pdfFotky/03-1_Mobile.png";
import fourMobile from "../../img/pdfFotky/04-1_Mobile.png";
import fiveMobile from "../../img/pdfFotky/05-1_Mobile.png";
import sixMobile from "../../img/pdfFotky/06-1_Mobile.png";
import sevenMobile from "../../img/pdfFotky/07-1_Mobile.png";
import eightMobile from "../../img/pdfFotky/08-1_Mobile.png";
import nineMobile from "../../img/pdfFotky/09-1_Mobile.png";
import tenMobile from "../../img/pdfFotky/010-1_Mobile.png";
import elevenMobile from "../../img/pdfFotky/011-1_Mobile.png";
import twelveMobile from "../../img/pdfFotky/012-1_Mobile.png";
import thirteenMobile from "../../img/pdfFotky/013-1_Mobile.png";
import fourteenMobile from "../../img/pdfFotky/014-1_Mobile.png";
import fifteenMobile from "../../img/pdfFotky/015-1_Mobile.png";
import sixteenMobile from "../../img/pdfFotky/016-1_Mobile.png";
import seventeenMobile from "../../img/pdfFotky/017-1_Mobile.png";
import eighteenMobile from "../../img/pdfFotky/018-1_Mobile.png";
import nineteenMobile from "../../img/pdfFotky/019-1_Mobile.png";
import twentyMobile from "../../img/pdfFotky/020-1_Mobile.png";
import twentyoneMobile from "../../img/pdfFotky/021-1_Mobile.png";
import twentytwoMobile from "../../img/pdfFotky/022-1_Mobile.png";
import twentythreeMobile from "../../img/pdfFotky/023-1_Mobile.png";
import twentyfourMobile from "../../img/pdfFotky/024-1_Mobile.png";
import twentyfiveMobile from "../../img/pdfFotky/025-1_Mobile.png";
import twentysixMobile from "../../img/pdfFotky/026-1_Mobile.png";
import twentysevenMobile from "../../img/pdfFotky/027-1_Mobile.png";
import twentyeightMobile from "../../img/pdfFotky/028-1_Mobile.png";

import MediaQuery from "react-responsive";

let wasRunned = true;
let mobileWasRunned = true;

const Pdf = () => {
    const [i, setI] = useState(0);
    const [flip, setFlip] = useState(0);
    const [o, setO] = useState(0);
    const [mobileFlip, setMobileFlip] = useState(0);

    useEffect(() => {
        try {
            const pages = document.querySelectorAll(".desktop > .page");
            if (wasRunned) {
                for (let x = 0; x < pages.length; x++) {
                    pages[x].style.zIndex = pages.length - x;
                }
                wasRunned = false;
            }
            if (flip === 1) {
                if (i !== 14) {
                    if (i === 0) {
                        document.querySelector(".desktop").classList.remove("first");

                        pages[0].style.transform = "rotateY(-180deg)";
                        pages[1].style.transform = "rotateY(0deg)";

                        pages[0].style.zIndex = 100;
                        pages[1].style.zIndex = 100;
                        setI(i + 1);
                    } else {
                        if (i === 13) {
                            setTimeout(() => {
                                document.querySelector(".desktop").classList.add("first");
                            }, 1000);
                        }
                        let z = parseInt(pages[i * 2 - 1].style.zIndex);

                        pages[i * 2 - 1].style.zIndex = parseInt(z - 1);

                        pages[i * 2].style.transform = "rotateY(-180deg)";
                        pages[i * 2 + 1].style.transform = "rotateY(0deg)";

                        pages[i * 2].style.zIndex = 100;
                        pages[i * 2 + 1].style.zIndex = 100;

                        setI(i + 1);
                    }
                }
            }
            if (flip === 0) {
                if (i !== 0) {
                    if (i === 1) {
                        setTimeout(() => {
                            document.querySelector(".desktop").classList.add("first");
                        }, 1000);
                    }
                    if (i === 14) {
                        document.querySelector(".desktop").classList.remove("first");
                        setTimeout(() => {
                            for (let x = 0; x < pages.length; x++) {
                                pages[x].style.zIndex = pages.length - x;
                            }

                            if (i !== 1) {
                                pages[i * 2 - 3].style.zIndex = 100;
                            }

                            pages[i * 2 - 2].style.zIndex = 100;
                            pages[i * 2 - 1].style.zIndex = 100;

                            pages[i * 2 - 1].style.transform = "rotateY(180deg)";
                            pages[i * 2 - 2].style.transform = "rotateY(0deg)";

                            setI(i - 1);
                        }, 300);
                    } else {
                        for (let x = 0; x < pages.length; x++) {
                            pages[x].style.zIndex = pages.length - x;
                        }

                        if (i !== 1) {
                            pages[i * 2 - 3].style.zIndex = 100;
                        }

                        pages[i * 2 - 2].style.zIndex = 100;
                        pages[i * 2 - 1].style.zIndex = 100;

                        pages[i * 2 - 1].style.transform = "rotateY(180deg)";
                        pages[i * 2 - 2].style.transform = "rotateY(0deg)";

                        setI(i - 1);
                    }
                }
            }
            setFlip(3);
        } catch {}
    }, [flip]);

    useEffect(() => {
        setTimeout(() => {
            const book = document.querySelector(".book");
            book.style.opacity = "1";
            const controls = document.querySelector(".controls");
            controls.style.opacity = "1";
            const loading = document.querySelector(".loading");
            loading.style.opacity = "0";
        }, 2000);
    }, []);

    useEffect(() => {
        try {
            const pages = document.querySelectorAll(".mobile > .page");
            if (mobileWasRunned) {
                for (let x = 0; x < pages.length; x++) {
                    pages[x].style.zIndex = pages.length - x;
                }
                mobileWasRunned = false;
            }
            if (mobileFlip === 1) {
                if (o !== 27) {
                    pages[o].classList.add("hidden");

                    setO(o + 1);
                }
            }
            if (mobileFlip === 0) {
                if (o !== 0) {
                    pages[o - 1].classList.remove("hidden");
                    setO(o - 1);
                }
            }
            setMobileFlip(3);
        } catch {}
    }, [mobileFlip]);

    return (
        <div className="pdf">
            <h2 className="loading">Načítavá sa...</h2>
            <MediaQuery maxWidth={800}>
                <div className="book mobile">
                    <img src={oneMobile} alt="" className="page" />
                    <img src={twoMobile} alt="" className="page" />
                    <img src={threeMobile} alt="" className="page" />
                    <img src={fourMobile} alt="" className="page" />
                    <img src={fiveMobile} alt="" className="page" />
                    <img src={sixMobile} alt="" className="page" />
                    <img src={sevenMobile} alt="" className="page" />
                    <img src={eightMobile} alt="" className="page" />
                    <img src={nineMobile} alt="" className="page" />
                    <img src={tenMobile} alt="" className="page" />
                    <img src={elevenMobile} alt="" className="page" />
                    <img src={twelveMobile} alt="" className="page" />
                    <img src={thirteenMobile} alt="" className="page" />
                    <img src={fourteenMobile} alt="" className="page" />
                    <img src={fifteenMobile} alt="" className="page" />
                    <img src={sixteenMobile} alt="" className="page" />
                    <img src={seventeenMobile} alt="" className="page" />
                    <img src={eighteenMobile} alt="" className="page" />
                    <img src={nineteenMobile} alt="" className="page" />
                    <img src={twentyMobile} alt="" className="page" />
                    <img src={twentyoneMobile} alt="" className="page" />
                    <img src={twentytwoMobile} alt="" className="page" />
                    <img src={twentythreeMobile} alt="" className="page" />
                    <img src={twentyfourMobile} alt="" className="page" />
                    <img src={twentyfiveMobile} alt="" className="page" />
                    <img src={twentysixMobile} alt="" className="page" />
                    <img src={twentysevenMobile} alt="" className="page" />
                    <img src={twentyeightMobile} alt="" className="page" />
                </div>
                <div className="controls">
                    <button
                        data-hover="hover"
                        className="left"
                        onClick={() => {
                            setMobileFlip(0);
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 448 512">
                            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                    </button>
                    <p>{o + 1}/28</p>
                    <button
                        data-hover="hover"
                        className="right"
                        onClick={() => {
                            setMobileFlip(1);
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 448 512">
                            <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                    </button>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={800}>
                <div className="book desktop first">
                    <img loading="lazy" src={one} alt="" className="page" />
                    <img loading="lazy" src={two} alt="" className="page" />
                    <img loading="lazy" src={three} alt="" className="page" />
                    <img loading="lazy" src={four} alt="" className="page" />
                    <img loading="lazy" src={five} alt="" className="page" />
                    <img loading="lazy" src={six} alt="" className="page" />
                    <img loading="lazy" src={seven} alt="" className="page" />
                    <img loading="lazy" src={eight} alt="" className="page" />
                    <img loading="lazy" src={nine} alt="" className="page" />
                    <img loading="lazy" src={ten} alt="" className="page" />
                    <img loading="lazy" src={eleven} alt="" className="page" />
                    <img loading="lazy" src={twelve} alt="" className="page" />
                    <img loading="lazy" src={thirteen} alt="" className="page" />
                    <img loading="lazy" src={fourteen} alt="" className="page" />
                    <img loading="lazy" src={fifteen} alt="" className="page" />
                    <img loading="lazy" src={sixteen} alt="" className="page" />
                    <img loading="lazy" src={seventeen} alt="" className="page" />
                    <img loading="lazy" src={eighteen} alt="" className="page" />
                    <img loading="lazy" src={nineteen} alt="" className="page" />
                    <img loading="lazy" src={twenty} alt="" className="page" />
                    <img loading="lazy" src={twentyone} alt="" className="page" />
                    <img loading="lazy" src={twentytwo} alt="" className="page" />
                    <img loading="lazy" src={twentythree} alt="" className="page" />
                    <img loading="lazy" src={twentyfour} alt="" className="page" />
                    <img loading="lazy" src={twentyfive} alt="" className="page" />
                    <img loading="lazy" src={twentysix} alt="" className="page" />
                    <img loading="lazy" src={twentyseven} alt="" className="page" />
                    <img loading="lazy" src={twentyeight} alt="" className="page" />
                </div>
                <div className="controls">
                    <button
                        data-hover="hover"
                        className="left"
                        onClick={() => {
                            setFlip(0);
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 448 512">
                            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                    </button>
                    <p>{i + 1}/15</p>
                    <button
                        data-hover="hover"
                        className="right"
                        onClick={() => {
                            setFlip(1);
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 448 512">
                            <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                    </button>
                </div>
            </MediaQuery>
        </div>
    );
};

export default Pdf;
